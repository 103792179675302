export const siteData = {
  "name": "RILAVIM",
  "address": "17 RUE PAUL BERT",
  "postCode": "60290",
  "city": "GOUVIEUX",
  "phone": "0258986632",
  "url": "https://rilavim.pro",
  "urlSub": "rilavim.pro",
  "price": "price_1QIupoRrL0GrV1T6i84UCQxw",
  "pk": "pk_live_51QIueRRrL0GrV1T65OEv8ydx1pXLNvDrgQx5r27KTNoLqkG1fF3BxFBBJr4sSv54qEwAOU6oa8deSTnHrzeOV49m00inwZjn9b",
  "sk": "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"
};